import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';


const prodConfig = {
  apiKey: "AIzaSyA8LiWibmUUQfCCKU0UQ_M0Q3_U3-UQp8Q",
    authDomain: "lead-collector-garcarek.firebaseapp.com",
    databaseURL: "https://lead-collector-garcarek.firebaseio.com",
    projectId: "lead-collector-garcarek",
    storageBucket: "lead-collector-garcarek.appspot.com",
    messagingSenderId: "943593189754"
};

const devConfig = {
  apiKey: "AIzaSyA8LiWibmUUQfCCKU0UQ_M0Q3_U3-UQp8Q",
    authDomain: "lead-collector-garcarek.firebaseapp.com",
    databaseURL: "https://lead-collector-garcarek.firebaseio.com",
    projectId: "lead-collector-garcarek",
    storageBucket: "lead-collector-garcarek.appspot.com",
    messagingSenderId: "943593189754"
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

var firebaseApp = firebase.initializeApp(config);

const db = firebaseApp.database();
const auth = firebaseApp.auth();
const sessionPersistence = firebase.auth.Auth.Persistence.SESSION;

export {
  db,
  auth,
  sessionPersistence,
};
