import React, { Component } from 'react';
import { compose } from 'recompose';
import withAuthorization from '../Session/withAuthorization';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { db } from '../../firebase';
import { Modal } from '../Modal/modal';
import './polls.css';

class Polls extends Component {
    constructor() {
        super();
        this.state = {
            leads: [],
            searchList: [],
            deleteModal: false,
            lead: null,
            firebaseLeads: null
        }
        this.filterList = this.filterList.bind(this);
        this.deleteInterviewee = this.deleteInterviewee.bind(this);
    }

    componentDidMount() {
        this.getLeads();
    }

    getLeads() {
        db.getLeads().on('value', snap => {
            const leads = snap.val();
            var leadsFromIpads = [];
            if (leads !== null) {
                Object.keys(leads).forEach(ipadKey => {
                    this.setState({ firebaseLeads: leads })
                    var ipads = [];
                    ipads.push(leads[ipadKey])
                    ipads.map((lead) => {
                        Object.keys(lead).forEach(key => {
                            leadsFromIpads.push(lead[key])
                        })
                        return leadsFromIpads
                    })
                });
            }
            this.setState({ leads: leadsFromIpads, searchList: leadsFromIpads })
        })
    }

    filterList(e) {
        var updatedList = this.state.leads;
        updatedList = updatedList.filter((item) => {
            var name = item.name_first + ' ' + item.name_last
            return name.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
        })

        this.setState({ searchList: updatedList })
    }

    deleteInterviewee(leadToDelete) {
        const { firebaseLeads } = this.state;
        var deviceUUID = null;
        var leadKey = null;
        Object.keys(firebaseLeads).forEach(ipadKey => {
            var ipads = [];
            ipads.push(firebaseLeads[ipadKey])
            ipads.map((lead) => {
                Object.keys(lead).forEach(key => {
                    if (leadToDelete.timestamp === lead[key].timestamp) {
                        deviceUUID = ipadKey
                        leadKey = key
                    }
                });
                return ipadKey
            });
        });
        db.deleteLead(deviceUUID, leadKey).then(() => {
            this.setState({ deleteModal: false });
        })
    }

    render() {
        const { leads, deleteModal, lead } = this.state;
        return (
            <div className={`polls-container`}>
                {lead ?
                    <Modal title={'Czy na pewno chcesz usunąć ' + lead.name_first + ' ' + lead.name_last + '?'}
                        subtitle={'Pamiętaj, że operacji nie można cofnąć.'}
                        modalState={deleteModal}
                        actionButton1={() => this.deleteInterviewee(lead)}
                        actionButtonName1={'Usuń'}
                        actionButton2={() => this.setState({ deleteModal: false })}
                        actionButtonName2={'Anuluj'}
                    /> : null}
                <label className={`polls-title`}>Ankietowani.</label>
                <div>
                    <label className={'search-label'}>Wyszukaj</label>
                    <input required type="text" className='search-textfield' onChange={this.filterList} />
                </div>
                {leads.length === 0 ? null :
                    <ListGroup>
                        {this.state.searchList.map((lead) => {
                            return (
                                <ListGroupItem key={lead.timestamp}>
                                    <label>{lead.name_first} {lead.name_last}</label>
                                    <button className={'remove-polls-button'} onClick={() => this.setState({ deleteModal: true, lead: lead })}>Usuń</button>
                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>
                }
            </div>
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default compose(
    withAuthorization(authCondition)
)(Polls);